<template>
  <validation-observer ref="non-conformity-description-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col lg="6">
          <b-form-group
            label="Real o potencial"
            label-for="non-conformity-real-or-potential"
          >
            <BaseSelect
              id="non-conformity-real-or-potential"
              :value="model.real_or_potential"
              :options="[{ name: 'Real', value: 1 }, { name: 'Potencial', value: 0 }]"
              :reduce="(item) => item.value"
              @input="update('real_or_potential', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Resumen, descripción corta de la no conformidad"
            label-for="non-conformity-resume"
          >
            <b-form-input
              id="non-conformity-resume"
              :value="model.resume"
              placeholder="Resumen de la no conformidad..."
              @input="update('resume', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Descripción a desarrollar de la no conformidad"
            label-for="non-conformity-description"
          >
            <b-form-textarea
              id="non-conformity-observations"
              :value="model.description"
              placeholder="Descripción de la no conformidad..."
              rows="3"
              @input="update('description', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import FormMixin from '@/mixins/formMixin'

export default {
  name: 'NonConformityDescriptionForm',
  components: { BaseSelect },
  mixins: [FormMixin],
}
</script>

<style scoped>

</style>
