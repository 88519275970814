<template>
  <validation-observer ref="non-conformity-causes-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col sm="12">
          <b-form-group
            label="Análisis de causas"
            label-for="non-conformity-cause-analysis"
          >
            <b-form-textarea
              id="non-conformity-cause-analysis"
              :value="model.cause_analysis"
              rows="3"
              @input="update('cause_analysis', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Imágenes del analisis de causas">
            <FileDropZone
              ref="images-cuases-input"
              :files="causesImages"
              accepted-extensions="image/*"
              @removed-file="removedCausesImages.push($event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Acción inmediata"
            label-for="non-conformity-inmediate-action"
          >
            <b-form-textarea
              id="non-conformity-inmediate-action"
              :value="model.inmediate_action"
              rows="3"
              @input="update('inmediate_action', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Imágenes de la acción inmediata">
            <FileDropZone
              ref="images-action-input"
              :files="actionImages"
              accepted-extensions="image/*"
              @removed-file="removedActionImages.push($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'

export default {
  name: 'NonConformityCauseForm',
  components: { FileDropZone },
  mixins: [FormMixin],
  data() {
    return {
      removedCausesImages: [],
      removedActionImages: [],
    }
  },
  computed: {
    causesImages() {
      return this.model?.images_causes || []
    },
    actionImages() {
      return this.model?.images_action || []
    },
  },
  methods: {
    getImagesData() {
      return {
        images_causes: this.$refs['images-cuases-input'].getFiles(),
        removedCausesImages: this.removedCausesImages,
        images_action: this.$refs['images-action-input'].getFiles(),
        removedActionImages: this.removedActionImages,
      }
    },
  },
}
</script>

<style scoped>

</style>
