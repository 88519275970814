<template>
  <validation-observer ref="non-conformity-result-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Estado"
                label-for="non-conformity-status"
              >
                <StatusSelect
                  :value="model.status"
                  :type="statusType"
                  @input="update('status', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Fecha de cierre">
                <b-form-datepicker
                  :value="model.non_conformity_date_close"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Fecha"
                  :label-help="null"
                  @input="update('non_conformity_date_close', $event)"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Departamento que cierra"
                label-for="non-conformity-department"
              >
                <BaseSelect
                  id="non-conformity-department"
                  :value="model.department"
                  http-method="get"
                  :resource="$data.$constants.RESOURCES.RESOURCE_DEPARTMENTS"
                  @input="update('department', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <validation-provider
                v-slot="validationContext"
                name="Evaluación de la eficacia"
                rules="required"
              >
                <b-form-group
                  label="Evaluación de la eficacia"
                  label-for="non-conformity-evaluation-effectiveness"
                >
                  <b-form-textarea
                    id="non-conformity-evaluation-effectiveness"
                    :value="model['evaluation_effectiveness_non-conformity']"
                    rows="4"
                    :state="$getValidationState(validationContext)"
                    @input="update('evaluation_effectiveness_non-conformity', $event)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import { NON_CONFORMITIES_STATUS_TYPE } from '@/api/status-api'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import BaseSelect from "@/components/ui/select/BaseSelect";

export default {
  name: 'NonConformityResultForm',
  components: {BaseSelect, StatusSelect },
  mixins: [FormMixin],
  data() {
    return {
      statusType: NON_CONFORMITIES_STATUS_TYPE,
    }
  },
  methods: {
    validateForm() {
      return this.$refs['non-conformity-result-form'].validate()
    },
  },
}
</script>

<style scoped>
</style>
