<template>
  <validation-observer ref="non-conformity-profile-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>

        <b-col lg="6">
          <validation-provider
            v-slot="validationContext"
            name="Fecha de apertura"
            rules="required"
          >
            <b-form-group
              label="Fecha de apertura"
            >
              <b-form-datepicker
                :value="model.non_conformity_date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Fecha de apertura"
                :label-help="null"
                :state="$getValidationState(validationContext)"
                @input="update('non_conformity_date', $event)"
                start-weekday="1"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col lg="6">
          <b-form-group
            v-show="numberVisible"
            label="Número"
            label-for="non-conformity-number"
          >
            <b-form-input
              id="non-conformity-number"
              :value="model.number"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
        >
          <b-form-group
            label="Origen"
            label-for="non-conformity-origin"
          >
            <NonConformityOriginSelect
              id="non-conformity-origin"
              :value="model.non_conformity_origin"
              @input="update('non_conformity_origin', $event)"
              @selection-change="handleOriginSelect"
            />
          </b-form-group>
        </b-col>
        <b-col offset-lg="6" />
        <b-col sm="6">
          <b-form-group
            v-if="otherOriginInputVisible"
            label="Otro origen"
            label-for="non-conformity-other-origin"
          >
            <b-form-input
              id="non-conformity-other-origin"
              :value="model.other_origin"
              placeholder="Otro origen"
              @input="update('other_origin', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col offset-lg="6" />
        <template v-if="issuerTypeSelectVisible">
          <b-col lg="6">
            <b-form-group
              label="Reclamac./Sugerencia"
              label-for="non-conformity-issuer"
            >
              <BaseSelect
                id="non-conformity-issuer"
                :value="model.non_conformity_issuer"
                :options="nonConformityIssuerTypes"
                @input="update('non_conformity_issuer', $event)"
                @selected="handleIssuerSelect"
              />
            </b-form-group>
          </b-col>
          <b-col offset-lg="6" />
        </template>
        <template>
          <b-col lg="6">
            <b-form-group
              label="Interna o externa"
              label-for="non-conformity-internal-or-external"
            >
              <BaseSelect
                id="non-conformity-internal-or-external"
                :value="model.internal_or_external"
                :options="[{ name: 'Interna', value: 'internal' }, { name: 'Externa', value: 'external' }]"
                :reduce="(item) => item.value"
                @input="update('internal_or_external', $event)"
                @selected="handleInternalOrExternalSelect"
              />
            </b-form-group>
          </b-col>
          <b-col offset-lg="6" />
        </template>
        <b-col
          v-show="providerSelectVisible"
          lg="6"
        >
          <b-form-group
            label="Proveedor"
            label-for="non-conformity-provider"
          >
            <BaseSelect
              id="non-conformity-provider"
              :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
              :value="model.provider"
              @input="update('provider', $event)"
              @selected="handelProviderSelect"
            />
          </b-form-group>
        </b-col>
        <b-col offset-lg="6" />
        <b-col lg="6">
          <b-form-group
            label="Proyecto"
            label-for="non-conformity-job"
          >
            <BaseSelect
              id="non-conformity-job"
              :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
              :value="model.job"
              @input="update('job', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col offset-lg="6" />

        <b-col lg="6">
          <b-form-group
            label="Nombre"
            label-for="non-conformity-issuer-name"
          >
            <b-form-input
              id="non-conformity-issuer-name"
              :value="model.issuer_name"
              placeholder="Nombre"
              @input="update('issuer_name', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            label="Dirección"
            label-for="non-conformity-issuer-address"
          >
            <b-form-input
              id="non-conformity-issuer-address"
              :value="model.issuer_address"
              placeholder="Dirección"
              @input="update('issuer_address', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            label="Teléfono"
            label-for="non-conformity-issuer-phone"
          >
            <b-form-input
              id="non-conformity-issuer-phone"
              :value="model.issuer_phone"
              placeholder="Teléfono"
              @input="update('issuer_phone', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            label="Email"
            label-for="non-conformity-issuer-email"
          >
            <b-form-input
              id="non-conformity-issuer-email"
              :value="model.issuer_email"
              placeholder="Email"
              @input="update('issuer_email', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Adjuntar Archivos">
            <FileDropZone
              ref="documentation-input"
              :files="files"
              @removed-file="removedFiles.push($event)"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            label="Accidente o Incidente"
            label-for="non-conformity-accident-or-incident"
          >
            <BaseSelect
              id="non-conformity-accident-or-incident"
              :value="model.accident_or_incident"
              :options="[{ name: 'Accidente', value: 'accident' }, { name: 'Incidente', value: 'incident' }]"
              :reduce="(item) => item.value"
              @input="update('accident_or_incident', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import NonConformityOriginSelect from '@/components/non-conformities/select/NonConformityOriginSelect.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'
import NonConformitiesApi from '@/api/non-conformities-api'
import ConfigurationApi from '@/api/configuration-api'
import ApiRestService from '@/api/base-api'

export default {
  name: 'NonConformityProfileForm',
  components: { FileDropZone, BaseSelect, NonConformityOriginSelect },
  mixins: [FormMixin],
  props: {
    numberVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      removedFiles: [],
      nonConformityIssuerTypes: [],
    }
  },
  computed: {
    files() {
      return this.model?.documentation || []
    },
    issuerTypeSelectVisible() {
      if (!this.model?.non_conformity_origin) {
        return false
      }

      const origins = ['documentación', 'administración', 'revisión sgi', 'auditoría sgi']

      return !origins.includes(this.model.non_conformity_origin.name.toLowerCase())
    },
    otherOriginInputVisible() {
      if (!this.model?.non_conformity_origin) {
        return false
      }

      return this.model.non_conformity_origin.name.toLowerCase() === 'otros'
    },
    providerSelectVisible() {
      return this.model?.internal_or_external === 'external' || this.model?.non_conformity_issuer?.name === 'Subcontratista/Proveedor'
    },
  },
  mounted() {
    this.loadNonConformityIssuerTypes()
  },
  methods: {
    validateForm() {
      return this.$refs['non-conformity-profile-form'].validate()
    },
    getDocumentationData() {
      return { documentation: this.$refs['documentation-input'].getFiles(), removedFiles: this.removedFiles }
    },
    async loadNonConformityIssuerTypes() {
      this.nonConformityIssuerTypes = await NonConformitiesApi.getIssuers()
    },
    async handleInternalOrExternalSelect(selection) {
      if (selection?.value !== 'internal') {
        return
      }

      const response = await ConfigurationApi.getCompanyConfiguration()
      const {
        name, email, phone, direction,
      } = response.data

      this.massUpdate({
        ...this.model, issuer_name: name, issuer_email: email, issuer_phone: phone, issuer_address: direction, provider: null,
      })
    },
    async handelProviderSelect(provider) {
      if (!provider) {
        return
      }

      const response = await ApiRestService.get(provider.id, this.$data.$constants.RESOURCES.RESOURCE_PROVIDERS)
      const {
        name, email, phone, address,
      } = response.data

      this.massUpdate({
        ...this.model, issuer_name: name, issuer_email: email, issuer_phone: phone, issuer_address: address,
      })
    },
    handleOriginSelect(origin) {
      this.massUpdate({
        ...this.model,
        non_conformity_origin: origin,
        non_conformity_issuer: null,
        internal_or_external: null,
        other_origin: null,
        provider: null,
      })
    },
    handleIssuerSelect(issuer) {
      this.massUpdate({
        ...this.model,
        non_conformity_issuer: issuer,
        provider: null,
      })
    },
  },
}
</script>

<style scoped>
</style>
