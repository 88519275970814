<template>
  <validation-observer ref="non-conformity-corrective-action-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Interna o externa"
                label-for="non-conformity-internal-or-external"
              >
                <BaseSelect
                  id="non-conformity-internal-or-external"
                  :value="model.internal_or_external"
                  :options="[{ name: 'Interna', value: 1 }, { name: 'Externa', value: 0 }]"
                  :reduce="(item) => item.value"
                  @input="update('internal_or_external', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-checkbox
                :checked="model.its_preventive"
                :value="1"
                :unchecked-value="0"
                class="my-2"
                @input="update('its_preventive', $event)"
              >
                Preventiva
              </b-form-checkbox>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Responsable implantación"
                label-for="non-conformity-implementation-resp"
              >
                <b-form-input
                  id="non-conformity-implementation-resp"
                  :value="model.implementation_resp"
                  placeholder="Responsable"
                  @input="update('implementation_resp', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Plazo previsto implantación"
                label-for="non-conformity-implementation-period"
              >
                <b-form-input
                  id="non-conformity-implementation-period"
                  :value="model.implementation_period"
                  placeholder="Periodo"
                  @input="update('implementation_period', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Acción correctiva / Acción preventiva"
                label-for="non-conformity-corrective-preventive-action"
              >
                <b-form-textarea
                  id="non-conformity-corrective-preventive-action"
                  :value="model.corrective_preventive_action"
                  rows="4"
                  @input="update('corrective_preventive_action', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Imágenes de la acción correctiva / preventiva">
            <FileDropZone
              ref="images-correction-input"
              accepted-extensions="image/*"
              :files="correctionImages"
              @removed-file="removedCorrectionImages.push($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'

export default {
  name: 'NonConformityCorrectiveActionForm',
  components: { FileDropZone, BaseSelect },
  mixins: [FormMixin],
  data() {
    return {
      removedCorrectionImages: [],
    }
  },
  computed: {
    correctionImages() {
      return this.model?.images_correction || []
    },
  },
  methods: {
    getImagesData() {
      return {
        images_correction: this.$refs['images-correction-input'].getFiles(),
        removedCorrectionImages: this.removedCorrectionImages,
      }
    },
  },
}
</script>

<style scoped>

</style>
