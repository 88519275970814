<template>
  <validation-observer ref="non-conformity-corrective-action-result-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Estado"
                label-for="non-conformity-corrective-action-status"
              >
                <StatusSelect
                  :value="model.status_action"
                  :type="statusType"
                  @input="update('status_action', $event)"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Fecha de cierre">
                <b-form-datepicker
                  :value="model.non_conformity_date_close_action"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  size="sm"
                  reset-button
                  :hide-header="true"
                  placeholder="Fecha"
                  :label-help="null"
                  @input="update('non_conformity_date_close_action', $event)"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Departamento afectado"
                label-for="non-conformity-department-action-affected"
              >
                <BaseSelect
                  id="non-conformity-department-action-affected"
                  :value="model.department_affected_action"
                  http-method="get"
                  :reduce="item => item.id"
                  :resource="$data.$constants.RESOURCES.RESOURCE_DEPARTMENTS"
                  @input="update('department_affected_action', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Evaluación de la eficacia"
                label-for="non-conformity-corrective-preventive-action"
              >
                <b-form-textarea
                  id="non-conformity-corrective-preventive-action"
                  :value="model.evaluation_effectiveness_preventive_corrective_action"
                  rows="4"
                  @input="update('evaluation_effectiveness_preventive_corrective_action', $event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import { NON_CONFORMITIES_STATUS_TYPE } from '@/api/status-api'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import BaseSelect from '@/components/ui/select/BaseSelect'

export default {
  name: 'NonConformityCorrectiveActionResultForm',
  components: { BaseSelect, StatusSelect },
  mixins: [FormMixin],
  data() {
    return {
      statusType: NON_CONFORMITIES_STATUS_TYPE,
    }
  },
}
</script>

<style scoped>

</style>
